import React from "react";
import FaqImage from "../Images/Faq-image.png";
import { Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useState } from "react";
import MyChatBot from "./MyChatBot";
import { Link } from "react-router-dom";

function HelpCenter() {
  const [chatVisible, setChatVisible] = useState(false);

  const toggleChatbot = () => {
    setChatVisible(!chatVisible);
  };

  const handlePdfDonwload = () => {
    console.log("handlePdfDonwload");

    const path = "/iWantHub-Web-App.pdf";
    const link = document.createElement("a");
    link.href = path;
    console.log("link: ", link);
    console.log("path: ", path);

    link.setAttribute("download", "iWantHub-Web-App.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="custom-container custom-margin-top-bottom">
      <div className="chat-icon" onClick={toggleChatbot}>
        {chatVisible ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-x"
          >
            <path d="M18 6 6 18" />
            <path d="m6 6 12 12" />
          </svg>
        ) : (
          <svg
            width="32"
            height="32"
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M41.8332 13.7083C41.8332 6.22947 35.7704 0.166664 28.2915 0.166664C23.9522 0.166664 20.0896 2.21312 17.6113 5.39001C28.1813 5.79867 36.6248 14.4966 36.6248 25.1667C36.6248 25.3965 36.6209 25.6254 36.6131 25.8533L37.3054 26.0385C39.3186 26.5772 41.1603 24.7354 40.6217 22.7222L40.3563 21.7304C40.142 20.9293 40.2708 20.0829 40.6154 19.3286C41.3974 17.6166 41.8332 15.7134 41.8332 13.7083Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M33.4998 25.1667C33.4998 34.3714 26.0379 41.8333 16.8332 41.8333C14.2592 41.8333 11.8215 41.2498 9.64516 40.2079C8.89715 39.8498 8.0497 39.7241 7.24856 39.9384L4.69431 40.6218C2.68112 41.1605 0.839326 39.3187 1.37798 37.3055L2.06141 34.7513C2.27577 33.9501 2.15003 33.1027 1.79192 32.3547C0.749995 30.1783 0.166504 27.7406 0.166504 25.1667C0.166504 15.9619 7.62843 8.5 16.8332 8.5C26.0379 8.5 33.4998 15.9619 33.4998 25.1667ZM9.5415 27.25C10.6921 27.25 11.6248 26.3173 11.6248 25.1667C11.6248 24.0161 10.6921 23.0833 9.5415 23.0833C8.39091 23.0833 7.45817 24.0161 7.45817 25.1667C7.45817 26.3173 8.39091 27.25 9.5415 27.25ZM16.8332 27.25C17.9838 27.25 18.9165 26.3173 18.9165 25.1667C18.9165 24.0161 17.9838 23.0833 16.8332 23.0833C15.6826 23.0833 14.7498 24.0161 14.7498 25.1667C14.7498 26.3173 15.6826 27.25 16.8332 27.25ZM24.1248 27.25C25.2754 27.25 26.2082 26.3173 26.2082 25.1667C26.2082 24.0161 25.2754 23.0833 24.1248 23.0833C22.9742 23.0833 22.0415 24.0161 22.0415 25.1667C22.0415 26.3173 22.9742 27.25 24.1248 27.25Z"
              fill="white"
            />
          </svg>
        )}
      </div>
      <div>
        {chatVisible && (
          <div
            className="react-chatbot-kit-chat-container show-chat"
            style={{ marginTop: "-27px" }}
          >
            <MyChatBot />

            {/* <Chatbot
                config={config}
                messageParser={MessageParser}
                actionProvider={ActionProvider}
                // placeholderIcon=""
                runInitialMessagesWithHistory
                disableScrollToBottom
              /> */}
          </div>
        )}
      </div>
      <div className="text-center custom-mx">
        <h3 className="Feather-font-bold feature-font-size">
          Master iWantHub – Your Ultimate Resource Hub
        </h3>
        <p className="OpenSans">
          Unlock the full power of iWantHub with tailored guides that show you
          how to take control of your files like a pro. Whether you’re
          streamlining your workflow on the web or staying organized on the go
          with our mobile app, we’ve got everything you need to make file
          management effortless.
        </p>
        <img className="custom-image-margin img-fluid" src={FaqImage} alt="" />
        <div className="res-d-flex ">
          {/* <a href="/iWantHub-Web-App.pdf" download> */}
          <Button
            variant="outline-success"
            className="btn custom-get-started-btn me-2"
            onClick={handlePdfDonwload}
          >
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.03429 5.96305L6.49114 8.49856C6.02369 8.9646 5.59488 9.3921 5.25624 9.77856C5.03877 10.0267 4.82145 10.2984 4.63737 10.5985L4.61259 10.5738C4.56555 10.5269 4.54201 10.5034 4.51839 10.4805C4.07636 10.0516 3.55641 9.71062 2.98636 9.47575C2.9559 9.4632 2.92498 9.45095 2.86314 9.42645L2.48449 9.27641C1.97153 9.07315 1.83482 8.41279 2.22514 8.02365C3.34535 6.90684 4.69032 5.56594 5.33941 5.29662C5.91185 5.05911 6.53023 4.98008 7.12664 5.06822C7.67311 5.14898 8.19006 5.42968 9.03429 5.96305Z"
                  fill="white"
                />
                <path
                  d="M13.3767 19.3132C13.5816 19.5212 13.7177 19.6681 13.8408 19.8251C14.0031 20.0322 14.1483 20.2523 14.2748 20.4829C14.4172 20.7426 14.5278 21.02 14.749 21.5748C14.929 22.0265 15.5272 22.1459 15.8746 21.7995L15.9586 21.7157C17.0788 20.5988 18.4237 19.2579 18.6938 18.6108C18.9321 18.04 19.0113 17.4235 18.9229 16.8289C18.8419 16.2841 18.5605 15.7688 18.0256 14.9273L15.474 17.4713C14.9959 17.9479 14.5576 18.385 14.1612 18.7273C13.9236 18.9325 13.6637 19.1376 13.3767 19.3132Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.4467 16.3769L20.2935 10.5476C21.1356 9.70811 21.5566 9.28836 21.7783 8.75458C22.0001 8.22081 22.0001 7.62719 22.0001 6.43996V5.87277C22.0001 4.04713 22.0001 3.13431 21.4312 2.56715C20.8624 2 19.9468 2 18.1157 2H17.5468C16.356 2 15.7606 2 15.2252 2.2211C14.6898 2.4422 14.2688 2.86195 13.4268 3.70146L7.57991 9.53078C6.59599 10.5117 5.98591 11.12 5.74966 11.7075C5.67502 11.8931 5.6377 12.0767 5.6377 12.2692C5.6377 13.0713 6.2851 13.7168 7.57991 15.0077L7.75393 15.1812L9.79245 13.1123C10.0832 12.8172 10.558 12.8137 10.8531 13.1044C11.1481 13.3951 11.1516 13.87 10.8609 14.1651L8.8162 16.2403L8.95326 16.3769C10.2481 17.6679 10.8955 18.3133 11.7 18.3133C11.8777 18.3133 12.0478 18.2818 12.2189 18.2188C12.8222 17.9966 13.438 17.3826 14.4467 16.3769ZM17.1935 9.5312C16.435 10.2874 15.2053 10.2874 14.4468 9.5312C13.6883 8.775 13.6883 7.54895 14.4468 6.79274C15.2053 6.03653 16.435 6.03653 17.1935 6.79274C17.952 7.54895 17.952 8.775 17.1935 9.5312Z"
                  fill="white"
                />
              </svg>
            </span>
            <span className="ps-2">Launch Web App Guide</span>
          </Button>
          {/* </a> */}
          <Button
            variant="outline-success"
            className="btn custom-login-btn cus-mar-left"
            // onClick={() => setModalShow(true)}
          >
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.75458 14.716L3.27222 16.6479C3.87647 18.9029 4.17859 20.0305 4.86351 20.7618C5.40432 21.3392 6.10421 21.7433 6.87466 21.9229C7.85044 22.1504 8.97798 21.8483 11.2331 21.244C13.4881 20.6398 14.6157 20.3377 15.347 19.6528C15.4077 19.5959 15.4664 19.5373 15.5233 19.477C15.1891 19.449 14.852 19.3952 14.5094 19.3271C13.8133 19.1887 12.9862 18.967 12.008 18.7049L11.9012 18.6763L11.8764 18.6697C10.8121 18.3845 9.92281 18.1457 9.21277 17.8892C8.46607 17.6195 7.7876 17.287 7.21148 16.7474C6.41753 16.0038 5.86193 15.0414 5.61491 13.982C5.43567 13.2133 5.48691 12.4594 5.62666 11.6779C5.76058 10.929 6.00109 10.0315 6.28926 8.95613L6.28926 8.95611L6.82365 6.96174L6.84245 6.8916C4.9219 7.40896 3.91101 7.71505 3.23687 8.34646C2.65945 8.88726 2.25537 9.58715 2.07573 10.3576C1.84821 11.3334 2.15033 12.4609 2.75458 14.716Z"
                  fill="#FF9600"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.8293 10.7154L20.3116 12.6473C19.7074 14.9024 19.4052 16.0299 18.7203 16.7612C18.1795 17.3386 17.4796 17.7427 16.7092 17.9223C16.6129 17.9448 16.5152 17.9621 16.415 17.9744C15.4999 18.0873 14.3834 17.7881 12.3508 17.2435C10.0957 16.6392 8.96815 16.3371 8.23687 15.6522C7.65945 15.1114 7.25537 14.4115 7.07573 13.641C6.84821 12.6652 7.15033 11.5377 7.75458 9.28263L8.27222 7.35077C8.3591 7.02654 8.43979 6.7254 8.51621 6.44561C8.97128 4.77957 9.27709 3.86298 9.86351 3.23687C10.4043 2.65945 11.1042 2.25537 11.8747 2.07573C12.8504 1.84821 13.978 2.15033 16.2331 2.75458C18.4881 3.35883 19.6157 3.66095 20.347 4.34587C20.9244 4.88668 21.3285 5.58657 21.5081 6.35703C21.7356 7.3328 21.4335 8.46034 20.8293 10.7154ZM11.0524 9.80589C11.1596 9.40579 11.5709 9.16835 11.971 9.27556L16.8006 10.5697C17.2007 10.6769 17.4381 11.0881 17.3309 11.4882C17.2237 11.8883 16.8125 12.1257 16.4124 12.0185L11.5827 10.7244C11.1826 10.6172 10.9452 10.206 11.0524 9.80589ZM10.2756 12.7033C10.3828 12.3032 10.794 12.0658 11.1941 12.173L14.0919 12.9495C14.492 13.0567 14.7294 13.4679 14.6222 13.868C14.515 14.2681 14.1038 14.5056 13.7037 14.3984L10.8059 13.6219C10.4058 13.5147 10.1683 13.1034 10.2756 12.7033Z"
                  fill="#FF9600"
                />
              </svg>
            </span>{" "}
            <span className="ms-2">Dive Into Mobile App Guide</span>
          </Button>
        </div>
      </div>
      <div>
        <div className="faq-border">
          <div className="faq-sub-border">
            <h3 className="Feather-font-bold normal-heading-fontsize">
              In the guide, learn how to:
            </h3>
            <div className="row">
              <div className="col-md-6  ">
                <div className="custom-card-background-color">
                  <h5 className="Feather-font-bold normal-fontsize">
                    File Management
                  </h5>
                  <ul className="mx-auto ul-color ">
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Upload files easily.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Create folders and subfolders.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Rename files for clarity.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Sort by name, date, or size.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Favorite important files.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6  ">
                <div className="custom-card-background-color">
                  <h5 className="Feather-font-bold normal-fontsize">
                    Search & Filters
                  </h5>
                  <ul className="mx-auto ul-color ">
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Global file search.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Filter by file type.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Filter by date.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Sort by size.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Preview files.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6  ">
                <div className="custom-card-background-color">
                  <h5 className="Feather-font-bold normal-fontsize">
                    File Sharing & Collaboration
                  </h5>
                  <ul className="mx-auto ul-color ">
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Share via link or direct.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Set file permissions.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Add comments to files.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Bulk file sharing.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Track shared files.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6  ">
                <div className="custom-card-background-color">
                  <h5 className="Feather-font-bold normal-fontsize">
                    Favorites & Quick Access
                  </h5>
                  <ul className="mx-auto ul-color ">
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Favorite files for easy access.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      View favorites in one place.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Organize favorite files.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Access on all devices.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Quickly find frequent files.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6  ">
                <div className="custom-card-background-color">
                  <h5 className="Feather-font-bold normal-fontsize">
                    Recycle Bin & Recovery
                  </h5>
                  <ul className="mx-auto ul-color ">
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Recover deleted files.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Permanently delete files.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Set file retention rules.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Bulk file recovery.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Manage recoveries.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6  ">
                <div className="custom-card-background-color">
                  <h5 className="Feather-font-bold normal-fontsize">
                    Customization & Security
                  </h5>
                  <ul className="mx-auto ul-color ">
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Light/dark theme switch.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Enable two-factor authentication.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Change your password.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Monitor active sessions.
                    </li>
                    <li
                      className="OpenSans font-sixteen py-2"
                      style={{ fontWeight: "600" }}
                    >
                      Log out securely.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row genenral-sec" id="faqs">
          <div className="col-md-5">
            <h3 className="Feather-font-bold">General FAQs</h3>
            <p
              className="OpenSans"
              style={{ fontSize: "16px", color: "#1E1E1E" }}
            >
              Need something cleared up? Here are our most frequently asked
              questions about the product and how it works.
            </p>
            <p
              className="OpenSans"
              style={{
                fontWeight: "700",
                fontSize: "16px",
                marginTop: "3rem",
              }}
            >
              Can’t Find What You Are Looking For?
            </p>
            <Link
              className="  button-decoration "
              to="/Contact-us"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <Button
                variant="outline-success"
                className="btn custom-login-btn  Feather-font-bold"
              >
                Let’s Talk
              </Button>
            </Link>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-6">
            <Accordion eventKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="Feather-font-bold">
                  Is iWantHub free?
                </Accordion.Header>
                <Accordion.Body
                  className="OpenSans"
                  style={{ fontWeight: "600" }}
                >
                  Yes, all features are 100% free.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="Feather-font-bold">
                  Who can use iWantHub?
                </Accordion.Header>
                <Accordion.Body
                  className="OpenSans"
                  style={{ fontWeight: "600" }}
                >
                  Any business looking for simple, free document management.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header className="Feather-font-bold">
                  What features are included?
                </Accordion.Header>
                <Accordion.Body
                  className="OpenSans"
                  style={{ fontWeight: "600" }}
                >
                  Secure document storage, uploads from PC or link, and basic
                  organization.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header className="Feather-font-bold">
                  Is iWantHub secure? 
                </Accordion.Header>
                <Accordion.Body
                  className="OpenSans"
                  style={{ fontWeight: "600" }}
                >
                  Yes, all documents are encrypted for security.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header className="Feather-font-bold">
                  How do I reset my password?
                </Accordion.Header>
                <Accordion.Body
                  className="OpenSans"
                  style={{ fontWeight: "600" }}
                >
                  Use the “Forgot Password” option on the login page.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header className="Feather-font-bold">
                  What should I do if I experience issues?
                </Accordion.Header>
                <Accordion.Body
                  className="OpenSans"
                  style={{ fontWeight: "600" }}
                >
                  Visit the Help Center or contact support.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header className="Feather-font-bold">
                  Can I delete my account?
                </Accordion.Header>
                <Accordion.Body
                  className="OpenSans"
                  style={{ fontWeight: "600" }}
                >
                  Yes, contact support or use the settings to delete your
                  account.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpCenter;
